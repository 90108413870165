@import "common/variables";

@import "../../node_modules/bootstrap/scss/bootstrap";
// @import "../../node_modules/select2/src/scss/core.scss";

@import "common/mixins";
@import "common/globals";

@import "layouts/login";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/main";
@import "layouts/navbar";
@import "layouts/cards";
@import "layouts/footer";
@import "layouts/menu-horizontal";
@import "components/floating-labels";
@import "components/bs-stepper";
@import "components/datatables";
@import "components/confirm";
@import "components/toastr";
@import "components/datepicker";
// @import "components/select2";
@import "components/timeline";
@import "components/chosen-js";
@import "components/daterangepicker";
@import "components/autocompleter";
@import "components/simplebar";
@import "components/hamburgers";


@include theme(theme-blue, #4572a9, #2f4576,#001f4a, #5CCEEE, #9eccec, #68a9de, #e5f1f8, #ffffff, #dc3545, #28a745);
@include theme(theme-cyan, #4572a9, #2f4576,#001f4a, #5CCEEE, #9eccec, #68a9de, #e5f1f8, #ffffff, #dc3545, #28a745);
@include theme(theme-yellow, #b5ddff, #2f4576,#001f4a, #ff9e1b, #ff9933, #ff9933, #e7e7e7, #ffffff, #dc3545, #28a745);
@include theme(theme-red, #5cdfe7, #83D0F2,#007d85, #ff8a50, #ff5722, #c41c00, #EAEAEA, #ffffff, #dc3545, #28a745);
@include theme(theme-green, #60ad5e, #2e7d32,#005005, #ffdd4b, #ffab00, #c67c00, #efebe9, #ffffff, #dc3545, #28a745);

.highcharts-data-table table {
    @extend .table;
    @extend .table-striped;

    .thead {
        @extend .thead-dark;
    }
}
