.navbar-actions {
    //background-color: tomato;
    
    .nav-profile {
        // .dropdown-toggle {
        //     @extend .border;
        // }

        .dropdown-menu a:not(:hover) {
            color: #515151;
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }

    .sidebarless & {
        .nav-profile {
            .dropdown-toggle {
                @extend .border-0;
            }
        }
    }
}
