.autocompleter {
    width: 100%;
    display: none;
}

.autocompleter-show {
    display: block;
}

.autocompleter,
.autocompleter-hint {
    position: absolute;
}

.autocompleter-list {
    list-style: none;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.autocompleter-item {
    cursor: pointer;
}

.autocompleter-item:hover {
    /* Hover State */
}

.autocompleter-item-selected {
    /* Selected State */
}

.autocompleter-item strong {
    /* Highlight Matches */
}

.autocompleter-hint {
    width: 100%;
    display: none;

    /** Need absolute position over input text */
}

.autocompleter-hint-show {
    display: block;
}

.autocompleter-hint span {
    color: transparent;
}
/*Custom*/
.autocompleter {
    width: calc(100% - 10px);
    // background: #dddddd;
    position: absolute;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
    top: 60px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
}

.autocompleter,
.autocompleter-hint {
    position: absolute;
}

.autocompleter-list {
    // box-shadow: inset 0px 0px 6px rgba(0,0,0,0.1);
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.autocompleter-item-selected {
    background: #ffffff;
}

.autocompleter-item {
    @include font-size($input-font-size-sm);

    height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    line-height: $input-line-height-sm;
    cursor: pointer;
    // transition: background-color 0.3s ease-in-out;
}

// .autocompleter-item span {
//     color: #bbbbbb;
// }

.autocompleter-hint {
    @extend .form-control-sm;

    color: #ccc;
    text-align: left;
    top: -30px;
    font-weight: 400;
    left: 0;
    width: 100%;
    //padding: 12px 12px 12px 13px;
    // font-size: inherit;
    display: none;
}

.autocompleter-hint span {
    color: transparent;
}

.autocompleter-hint-show {
    display: block;
}

.autocompleter-closed {
    display: none;
}