.left-sidebar {
    // padding-bottom: 0;
    // padding-left: 0.5rem;
    // padding-right: 0.5rem;
    // padding-top: 0;

    @include media-breakpoint-down(sm) {
        padding-top: 128px;
        transform: translate(-260px, 0);
    }

    .sidebar-show & {
        @include media-breakpoint-down(sm) {
            transform: translate(0, 0);
        }

        @include media-breakpoint-up(md) {
            transform: translate(0, 0);
            width: 60px;
            z-index: 850;

            .nav-link {
                svg {
                    transition: width 0.3s ease-in;
                    width: 100%;
                }

                span {
                    display: none;
                }
            }

            .dropdown-toggle:after {
                display: none;
            }
        }
    }

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-top: 60px;
    height: 100%;
    width: 260px;
    z-index: 810;
    transition: transform .3s ease-in-out,width .3s ease-in-out;

    .sidebar-content {
        // height: auto;
        height: calc(100vh - 64px);
        padding-bottom: 10px;
    }

    .sidebar-menu {
        white-space: nowrap;
        overflow: hidden;
        font-size: 0.9rem;

        .sidebar-profile {
            white-space: normal;
            padding: 15px 15px 0;

            h4 {
                @include font-size(12);
            }

            small {
                opacity: 0.75;
            }
        }

        .user-profile {
            figure {
                display: flex;
                flex-direction: row;
                align-items: center;

                figcaption {
                    line-height: 1;
                }
            }
        }

        .caption-2 {
            padding: 30px 15px 5px;
        }

        .nav-item {
            .nav-link {
                @include font-family('PT Sans', 700);

                position: relative;
                display: flex;
                white-space: nowrap;
                align-items: center;
                // padding: 15px 15px;
                // line-height: 25px;
                padding: 0.875rem 1rem;
                opacity: 0.8;

                &.dropdown-toggle {
                    &::after {
                        position: absolute;
                        right: 15px;
                        transition: all 0.3s ease-out;
                    }

                    &[aria-expanded="true"] {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .sidebar-menu-child {
                .nav-link {
                    font-weight: 400;
                }
            }
        }
    }
}

.right-sidebar {
    position: fixed;
    background-color: #333;
    top: 0;
    right: 0;
    bottom: 0;
    padding-top: 64px;
    height: 100%;
    width: 800px;
    z-index: 810;
    transition: transform .3s ease-in-out,width .3s ease-in-out;
    transform: translate(800px, 0);

    .rightSidebartoggler {
        position: absolute;
        width: 42px;
        right: 800px;
        top: auto;
        border-top-left-radius: 40px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 0;
        // padding: 0.625rem;
    }
    
    @include media-breakpoint-down(sm) {
        padding-top: 128px;
    }
    
    .right-sidebar-show & {
        transform: translate(0, 0);

        @include media-breakpoint-down(sm) {
            width: 100%;
            transform: translate(0, 0);

            .rightSidebartoggler {
                left: 0;
                right: auto;
                top: auto;
                border-top-left-radius: 0;
                border-top-right-radius: 40px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 40px;
                // padding: 0.625rem;
            }
        }

        // @include media-breakpoint-up(md) {
        //     transform: translate(0, 0);
        //     width: 60px;
        //     z-index: 850;

        //     .nav-link {
        //         svg {
        //             transition: width 0.3s ease-in;
        //             width: 100%;
        //         }

        //         span {
        //             display: none;
        //         }
        //     }

        //     .dropdown-toggle:after {
        //         display: none;
        //     }
        // }
    }
}

.sidebartoggler {
    width: 24px;
    margin: 0;
    padding: 0;

    &::before,
    &::after, 
    span {
        display: block;
        content: '';
        margin: 4px 0;
        height: 2px;
        border-radius: 3px;
        background-color: gray;
        width: 24px;
        transition: all .2s ease-in-out;
    }

    &:hover {
        span {
            width: 14px;
        }
    }

    .sidebar-show & {
        span {
            width: 14px;
        }

        &:hover {
            span {
                width: 24px;
            }
        }
    }
}

.has-alert {
    .left-sidebar {
        padding-top: 164px;

        @include media-breakpoint-up(md) {
            padding-top: 100px !important;      
        }

        .sidebar-content {
            height: calc(100vh - 100px);
        }
    }
}
