footer.main-footer {
    position: fixed;
    bottom: 0;
    padding: 8px 0;
    width: 100%;
    height: auto;
    
    @include media-breakpoint-down(sm) {
        margin-left: 0;
        // padding-top: 128px;        
    }

    .sidebar-show & {
        @include media-breakpoint-down(md) {
            margin-left: 260px;        
        }

        @include media-breakpoint-up(md) {
            margin-left: 60px;        
        }
    }

    // min-height: 100%;
    z-index: 800;
    transition: transform .3s ease-in-out,margin .3s ease-in-out;
    margin-left: 260px;
    z-index: 820;
    // padding-top: 64px;
}