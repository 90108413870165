.login {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    background-image: url(../images/login__bg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: auto;
    height: 100%;

    .wrapper {
        height: 100%;
        overflow-y: hidden;
    }

    .form-signin {
        width: 100%;
        max-width: 420px;
        padding: 15px;
        margin: auto;
    }
    
    footer {
        @extend .form-signin;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid rgba(255,255,255,0.05);
        color: rgba(255,255,255,0.5);
        font-size: 80%;

        .pre-footer,
        .post-footer {
            display: flex;
        }

        .pre-footer {
            align-items: center;
            justify-content: space-between;
        }

        .post-footer {
            align-items: center;
            justify-content: center;
        }
    }
}