header.main-header {
    position: fixed;
    width: 100%;
    max-height: 100px;
    z-index: 1030;

    .navbar-user {
        margin-bottom: 0 !important;
        padding-bottom: 0;
        padding-top: 0;

        svg {
            @extend .align-self-center;
            @extend .mr-1;
        }

        .navbar-text {
            @extend .overline;

            font-size: 70%;
            color: $white;
        }

        .profile-item {
            &:before {
                position: absolute;
                content: '';
                height: 24px;
                width: 1px;
                background-color: rgba(255, 255, 255, 0.5);
                left: 0;
                top: 8px;
            }
        }
    }

    .brand {
        @include media-breakpoint-down(sm) {
            width: 100%;
            float: none;
        }

        .sidebar-show & {
            @include media-breakpoint-up(md) {
                width: 60px;
                margin: 0 auto;
                padding: 0;

                span {
                    display: none;
                }
            }
        }

        display: block;
        float: left;
        height: 64px;
        line-height: 64px;
        text-align: center;
        width: 260px;
        padding: 0 15px;
        font-weight: 300;
        transition: width .3s ease-in-out;
        overflow: hidden;
    }

    .navbar:not(.navbar-user) {
        @include media-breakpoint-down(sm) {
            margin: 0;
        }

        .sidebar-show & {
            @include media-breakpoint-up(sm) {
                margin: 0;
            }
        }

        transition: margin-left .3s ease-in-out;
        margin: 0 0 0 260px;
        padding: 0 1rem;
        border: none;
        min-height: 64px;
        border-radius: 0;
        background-color: #ffffff;

        .sidebartoggler {
            float: left;
        }

        .navbar-left {
            @extend .d-flex;

            .page-header {
                display: flex;
                align-items: baseline;

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    @extend .mb-0;
                    // @extend .caption;

                    // text-transform: uppercase;

                    span {
                        @include font-family();
                        @extend .overline;

                        display: block;
                        color: rgba(0, 0, 0, 0.85);
                    }
                }
            }
        }

        .navbar-right {
            float: right;
            
            a.dropdown-toggle {
                // display: flex;
                // align-items: center;
                color: rgba(0, 0, 0, 0.75);
            }

            .nav-item {
                line-height: 30px;
            }

            .nav-profile {
                a {
                    display: flex;
                    align-items: center;
                }
                
                .tagname {
                    @extend  .ml-2;

                    text-align: left;
                    height: 30px;
                    line-height: 1;
                }
            }
        }
    }

    .sidebarless:not(.menu-h) & {
        display: flex;
        justify-content: space-between;
        
        .brand {
            text-align: left;
        }

        .navbar {
            justify-content: flex-end;
            margin: 0;
        }
    }
}

.dashboard .page-breadcrumb {
    background-color: #ffffff;
}

.sidebar-show {
    @include media-breakpoint-up(md) {
        .user-enterprise,
        .caption-2 {
            display: none;
        }

        .user-profile {
            width: 30px;
            
            figure {
                figcaption {
                    display: none;
                } 
            }
        }
    }
}