//
// Datatables core
//
.dataTables_wrapper {
    .header,
    .footer {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-between;
    }

    // td {
    //     @include font-size(13);
    // }

    .dataTables_info {
        font-size: 0.875rem;
        line-height: 1.5;
        padding-top: 0.5em !important;
    }

    .dataTables_paginate {
        .pagination {
            .paginate_button {
                a {
                    @extend .rounded-pill;
    
                    border-width: 2px;
                    transition: border-color 0.3s ease-in, color 0.3s ease-in;

                    &:focus {
                        box-shadow: none;
                    }
                }

                &.active {
                    a {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

//
// Datatables buttons
//
.dt-buttons button {
    @extend .btn-light;
    @extend .btn-sm;
    //@extend .overline;
  }
  

  .dataTables_paginate {
      a {
        @extend .btn-sm;
        //@extend .overline;
      }
  }

  .sorting_disabled {
    padding-right: 0px !important;
  }

  .paging-info {
    .pagination {
        .disabled {
            a {
                opacity: 0//.65;
            }
        }
    }
  }

  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: transparent;
    border-color: transparent;
}