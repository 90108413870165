.card-featuretes {
    .card {
        @extend .border-0;
        @extend .shadow-sm;

        transition: box-shadow 0.4s ease;

        a,
        a:hover {
            color: inherit;
        }

        picture {
            @extend .d-block;
            
            transition: 0.4s transform;
            backface-visibility: hidden;
            perspective: 1000;
            text-align: center;
        }

        .card-title {
            @extend .subtitle-1;

            font-weight: 700;
            text-transform: uppercase;
        }

        .card-text {
            margin: 0;
            font-size: 85%;
        }

        .card-title,
        .card-text {
            transition: 0.4s transform;
            backface-visibility: hidden;
            perspective: 1000;
        }

        .card-body {
            padding: 1rem;
        }
        
        &:hover {
            @extend .shadow;

            .card-title,
            .card-text,
            .card-info {
                transform: translateY(-5px);
            }

            picture {
                transform: translateY(5px);
            }
        }
    }
}
