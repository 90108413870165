$enable-responsive-font-sizes: true;

//
// Colors
//
$footer-bg-color: gray;
$gray-100: #f5f5f5;

$white: #FFFFFF;
$black: #000000;
$link-decoration: none;
$link-hover-decoration: none;

$body-bg: #e5f1f8;

$toast-max-width: 500px;

$input-group-addon-bg: transparent;
$input-focus-box-shadow: none;

$modal-header-border-color: transparent;
$modal-header-padding: 1rem 1rem 0;

$nav-tabs-border-width: 3px;
$nav-tabs-link-active-color: rgba(0, 0, 0, 0.75);
$nav-tabs-link-active-bg: transparent;

$label-margin-bottom: 0.25rem;

$table-accent-bg: rgba(0, 0, 0, 0.05);
$table-cell-padding: 0.5rem;

$input-disabled-bg: #eaeaea;

$font-size-base: 1rem;
$font-size-lg:                $font-size-base * 1 !default;
$font-size-sm:                $font-size-base * 0.75 !default;

$btn-padding-y: 0.625rem;
$btn-padding-x: 1.875rem;
$btn-font-size: 0.8125rem;
$btn-line-height: 1.125rem;

// $btn-padding-y-lg
// $btn-padding-x-lg
// $btn-font-size-lg
// $btn-line-height-lg
// $btn-border-radius-lg

// $btn-padding-y-lg
$btn-padding-x-sm: 0.625rem;
// $btn-font-size-sm: 0.75rem;
// $btn-line-height-lg
// $btn-border-radius-lg

$small-font-size: 75%;

$custom-file-text: (
  en: "Browse",
  es: "Elegir"
);

@mixin theme($name,  $primary-color-light, $primary-color, $primary-color-dark, $secondary-color-light, $secondary-color, $secondary-color-dark, $body-color, $surface-color, $danger-color, $success-color) {
    $theme-colors: (
        "primary-light": $primary-color-light,
        "primary": $primary-color,
        "primary-dark": $primary-color-dark,
        "secondary-light": $secondary-color-light,
        "secondary": $secondary-color,
        "secondary-dark": $secondary-color-dark,
        "body-color": $body-color,
        "surface": $surface-color,
        "danger": $danger-color,
        "success": $success-color,
        "action": $secondary-color
    );

    $primary-light: map-get($theme-colors, primary-light);
    $primary: map-get($theme-colors, primary);
    $primary-dark: map-get($theme-colors, primary-dark);
    $secondary-light: map-get($theme-colors, secondary-light);
    $secondary: map-get($theme-colors, secondary);
    $secondary-dark: map-get($theme-colors, secondary-dark);
    $body-color: map-get($theme-colors, body-color);
    $surface: map-get($theme-colors, surface);
    $danger: map-get($theme-colors, danger);
    $success: map-get($theme-colors, success);

    $action: map-get($theme-colors, action);

    $sidebarBg: $primary-dark;
    $sidebarBgContrast: $secondary-light;

    .#{$name} {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
        -webkit-animation: autofill-#{$name} 0s forwards;
        animation: autofill-#{$name} 0s forwards;
        }

        @keyframes autofill-#{$name} {
            100% {
                //background: transparent;
                //color: inherit;
            }
        }

        @-webkit-keyframes autofill-#{$name} {
            100% {
                //background: transparent;
                //color: inherit;
            }
        }

        ::selection {
            background: rgba($secondary-light, 0.35);
        }

        ::-moz-selection {
            background: rgba($secondary-light, 0.35);
        }

        // $body-color: $body-color;
        background-color: $body-color;

        // a:not(.btn) {
        //     color: $secondary-light;
        // }

        // a:not(.dropdown-item):not(.btn) {
        //     color: $secondary;

        //     @include hover {
        //         color: $secondary-light;
        //     }
        // }

        //
        // Main Header
        //
        .main-header {
            .alert {
                color: color-yiq(#212529);
                background-color: #212529;

                .btn-link {
                    color: $action;

                    @include hover {
                        color: rgba($action, 0.9);
                    }
                }
            }

            .brand {
                background-color: $sidebarBg;
                color: color-yiq($sidebarBg);
                border-bottom: 1px solid rgba($white, 0.1);

                &:hover {
                    color: rgba(color-yiq($sidebarBg), 0.75);
                }

                // span {
                //     color: $sidebarBgContrast;
                // }
            }
        }

        .hr-40 {
            &::after {
                display: none;

                @include media-breakpoint-up(lg) {
                    position: relative;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 40px;
                    background-color: $secondary-dark;
                }
            }
        }

        .card-featuretes {
            .card {
                border: 1px solid rgba($primary, 0.5);
                margin-top: 15px;
                margin-bottom: 15px;

                &:hover {
                    small {
                        color: $primary !important;
                    }
                }
            }
        }

        .card-header {
            color: $primary-dark
        }

        #lgrad {
            .first {
                stop-color: $secondary-light;
                stop-opacity:1
            }

            .last {
                stop-color: $secondary-dark;
                stop-opacity:1
            }
        }

        // Image Radio
        input {
            &:active,
            &:checked {
                &+.img-radio-label {
                    border-color: $primary-dark;

                    h3 {
                        color: $primary-dark;
                    }
                }
            }
        }

        .img-radio-label:hover {
            border-color: $primary-dark;

            h3 {
                color: $primary-dark;
            }
        }

        // Login
        &.login {
            background-color: $primary-dark;

            .wrapper {
                color: color-yiq($primary-dark);
            }
        }

        .bs-stepper-header {
            // background-color: $secondary-color-light;
            // border: 1px solid rgba($primary-color-dark, 0.125);

            .step-trigger {
                .bs-stepper-circle {
                    background-color: rgba($primary-color-dark, 0.55);
                    transition: all 0.3s ease-in;
                }

                .bs-stepper-label {
                    color: rgba($primary-color-dark, 0.55);
                    transition: all 0.3s ease-in;
                }

                &:hover {
                    .bs-stepper-circle {
                        background-color: $primary-color-dark;
                    }

                    .bs-stepper-label {
                        color: $primary-color-dark;
                    }
                }
            }

            .active .bs-stepper-circle {
                background-color: $primary-color-dark;
            }

            .active .bs-stepper-label {
                color: $primary-color-dark;
            }

            .bs-stepper-line,
            .bs-stepper .line {
                background-color: rgba($primary-color-dark, 0.12);
            }
        }

        //danger
        &#error {
            .brand {
                background-color: $sidebarBg;
                color: $white;
            }

        }

        &.sidebarless {
            //.brand,
            .navbar {
                background-color: $sidebarBg;

                a:not(.dropdown-item) {
                    color: color-yiq($sidebarBg);
                    // border-left: 1px solid transparent;
                    transition: background-color 0.3s ease-in-out;
    
                    svg {
                        color: $sidebarBgContrast;
                    }
    
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.1);
                    }
                }
            }
        }

        &.menu-h {
            .navbar {
                min-height: 56px !important;
                background: linear-gradient(to right, $primary-color-dark 0%,$primary-color 50%,$primary-color-light 100%);
                padding: 0.5rem 1rem !important;
            }
        }

        // Sidebar
        .left-sidebar {
            background-color: $sidebarBg;

            a {
                color: color-yiq($sidebarBg);
                // border-left: 1px solid transparent;
                transition: background-color 0.3s ease-in-out;

                svg {
                    color: $sidebarBgContrast;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }

            .sidebar-menu {
                .user-enterprise {
                    h4 {
                        color: $secondary-color;
                    }
                }

                .caption-2 {
                    color: rgba($secondary-color-light, 0.8);
                }

                .sidebar-profile {
                    color: rgba($white, 0.8);
                }

                .nav-item .active {
                    background-color: rgba($black, 0.1);
                    // border-radius: 4px;
                    // background-color: $sidebarBgContrast;
                    color: $sidebarBgContrast;
                    // border-left: 1px solid $sidebarBgContrast;

                    svg {
                        // color: $sidebarBgContrast;
                        color: $sidebarBgContrast;
                    }
                }

               .dropdown-toggle {
                    // background-color: inherit;
                    transition: background-color 0.1s ease-in-out;

                    .sidebar-menu-child {
                        background-color: inherit;
                        transition: background-color 0.1s ease-in-out;
                    }

                   &[aria-expanded="true"] {
                       background-color: rgba($black, 0.1);

                       + .sidebar-menu-child {
                            background-color: rgba($black, 0.1);

                            .active {
                                // background-color: rgba(255, 255, 255, 0.95);
                                color: $secondary;

                                svg {
                                    color: $secondary;
                                }
                            }
                       }
                   }
               }
            }
        }

        .simplebar-scrollbar::before {
            background-image: linear-gradient(-131deg, rgba($secondary-color, 1) 0%, rgba($secondary-color-light, 1) 100%);
        }

        .divider {
            margin: 7px 0;
            border-top: 1px solid color-yiq($primary-color-dark);
            opacity: 0.1;
        }


        // Main
        // .page-wrapper {
        //     background-color: rgba($secondary-color-dark, 0.2);
        // }

        // Tablas
        .table {
            thead {
                th {
                    color: $primary-dark
                }

                &.thead-dark {
                    th {
                        color: $secondary;
                    }
                }
            }

            tbody {
                tr {
                    &.selected {
                        background-color: rgba($secondary-color-light, 0.35) !important;
                    }
                }
            }

            &.table-dark {
                thead {
                    th {
                        color: $secondary;
                    }
                }
            }

            &.table-hover tbody tr:hover {
                color: color-yiq($secondary-light);
                background-color: rgba($secondary-light, 0.35);
            }
        }

        .breadcrumb {
            background-color: transparent;
        }

        &.print {
            @include font-size(14);

            font-family: Arial, Helvetica, sans-serif;
            -webkit-print-color-adjust:exact;

            h2 {
                color: $primary-dark;
            }

            .overline {
                font-family: Arial, Helvetica, sans-serif;
                font-weight: bold;
                letter-spacing: 1px;
                color: $primary-dark;
            }

            hr {
                border-top: 2px solid $primary-dark;
            }

            .table {
                border-width: 1px;
                border-style: solid;
                border-color: $primary-dark;

                thead {
                    tr {
                        th {
                            font-family: Arial, Helvetica, sans-serif;
                            color: #FFFFFF;
                            border-bottom: 2px solid $primary-dark;
                            border-top: 1px solid $primary-dark;
                            line-height: 1.15;
                            // background-color: $primary-dark;
                            // border-color: $primary-dark;
                            background-image: url(../images/table__bg--cyan.gif);
                        }
                    }
                }

                tbody {
                    td {
                        border-top: 1px solid $primary-dark;
                    }
                }
            }

            ol {
                li {
                    margin-bottom: 1rem;

                    .form-check {
                        .form-check-label {
                            // font-weight: 600;
                            color: $primary-dark;
                        }
                    }

                    div > p {
                        // font-weight: 600;
                        color: $primary-dark;
                    }
                }
            }
        }

        .childTable {         
            table {       
                thead {
                    tr {
                        th{
                            background-color: $secondary-color;
                        }
                    }
                }
            }
        }

        // Tabs
        .nav-tabs {
            > a.nav-item {
                svg {
                    .fill-color {
                        fill: rgba(0, 0, 0, 0.5);
                        transition: fill 0.3s ease-in-out;
                    }

                    .stroke-color {
                        stroke: rgba(0, 0, 0, 0.5);
                        transition: stroke 0.3s ease-in-out;
                    }
                }

                &.active,
                &:hover {
                    color: $primary;
                    border-bottom: 3px solid $secondary-light;

                    svg {
                        .fill-color {
                            fill: $secondary-light;
                        }

                        .stroke-color {
                            stroke: $secondary-light;
                        }
                    }
                }
            }
        }

        .tabbed {
            background-color: $primary-light;
            color: color-yiq($primary-light);

            .nav-tabs {
                border-top: 3px solid transparent;
                border-bottom: 3px solid rgba(color-yiq($primary-light), 0.3);
                
                > a.nav-item {
                    color: rgba(color-yiq($primary-light), 0.75);
    
                    svg {
                        .fill-color {
                            fill: color-yiq($primary-light);
                            transition: fill 0.3s ease-in-out;
                        }
    
                        .stroke-color {
                            stroke: color-yiq($primary-light);
                            transition: stroke 0.3s ease-in-out;
                        }
                    }

                    &.active,
                    &:hover {
                        color: $primary-dark;
                        border-bottom: 3px solid $secondary-light;

                        svg {
                            .fill-color {
                                fill: $secondary-light;
                            }

                            .stroke-color {
                                stroke: $secondary-light;
                            }
                        }
                    }
                }

                li.nav-item {
                    padding: .5rem .75rem 0 .75rem;

                    a.nav-link {
                        border-color: transparent;
                        color: color-yiq($primary-light);

                        svg {
                            .fill-color {
                                fill: color-yiq($primary-light);
                                transition: fill 0.3s ease-in-out;
                            }
        
                            .stroke-color {
                                stroke: color-yiq($primary-light);
                                transition: stroke 0.3s ease-in-out;
                            }
                        }

                        &.active,
                        &:hover {
                            color: color-yiq($primary-light);
                            border-bottom: 3px solid $secondary-light;

                            svg {
                                .fill-color {
                                    fill: $secondary-light;
                                }

                                .stroke-color {
                                    stroke: $secondary-light;
                                }
                            }
                        }
                    }
                }
            }
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            color: color-yiq($secondary-light);
            border-color: $secondary-light;
            background-color: $secondary-light;
        }

        // .overline {
        //     color: $secondary-light;
        // }

        tr.shown {
            color: $primary-dark;

            + tr {
                // color: color-yiq($secondary-light);
                // background-color: rgba($secondary-light, 0.15);

                // table th {
                //     background-color: rgba(0, 0, 0, 0.015);
                //     color: #515151;
                // }
            }
        }

        //
        // Datepicker
        //
        .ui-datepicker {
            background-color: #FFFFFF;
            border: 1px solid $secondary-light;

            .ui-datepicker-title {
                @extend .caption;

                color: $primary-dark;
            }

            .ui-datepicker-unselectable {
                color: rgba(0, 0, 0, 0.35);
            }

            .ui-datepicker-today {
                //background-color: $secondary-color-light;
                color: $secondary-color;
                border: 1px solid $secondary;
            }

            .ui-datepicker-calendar {
                th {
                    @extend .overline;
                }

                td a.ui-state-default {
                    color: rgba(0, 0, 0, 0.75);
                    border: 1px solid transparent;

                    &.ui-state-active {
                        background-color: $secondary-color-light;
                    }

                    &:hover {
                        //color: rgba(0, 0, 0, 0.85);
                        color: $secondary-color;
                        //border: 1px solid $secondary;
                    }
                }
            }
        }

        .nav-actions a:not(.text-danger),
        .nav-actions button:not(.text-danger) {
            color: rgba(0, 0, 0, 0.5);

            @include hover {
                color: rgba(0, 0, 0, 0.75);
            }

            svg {
                .fill-color:not(.fill-danger) {
                    fill: $primary;

                    @include hover {
                        color: rgba($primary, 0.9);
                    }
                }

                .stroke-color:not(.stroke-danger) {
                    stroke: $primary;

                    @include hover {
                        color: rgba($primary, 0.9);
                    }
                }
            }
        }

        .page-item {
            .page-link {
                color: rgba(0, 0, 0, 0.75);
                border-color: transparent;

                &:hover {
                    color: $primary-dark;
                }
            }

            &.active {
                .page-link {
                    color: $primary-dark;
                    background-color: $white;
                    border-color: $action !important;
                }
            }
        }

        .chosen-container .chosen-results li.highlighted {
            background-color: #1E90FF;
            color: #ffffff;
        }

        .autocompleter-item:hover {
            background-color: #1E90FF;
            color: #FFFFFF;
        }

        footer.main-footer {
            background-color: #CEE8FE;
        }

        // Colores
        @each $color, $value in $theme-colors {
            .btn-#{$color} {
                @include button-variant($value, $value);

                .icon {
                    svg {
                        path {
                            stroke: color-yiq($value);
                        }
                    }
                }
            }

            .btn-outline-#{$color} {
                @include button-outline-variant($value);
            }

            .badge-#{$color} {
                @include badge-variant($value);
            }

            .text-#{$color} {
                color: $value !important;
            }

            .alert-#{$color} {
                @include alert-variant(lighten($value, 50%), lighten($value, 25%), $value);
            }

            .bg-#{$color} {
               background-color: $value !important;
            }

            .fill-#{$color} {
                fill: $value !important;
            }

            .stroke-#{$color} {
                stroke: $value !important;
            }
        }

        // .btn-link {
        //     color: $primary;

        //     @include hover {
        //         color: rgba($primary, 0.9);
        //     }
        // }

        // .btn-light {
        //     @include button-variant(white,white,white,white,white, white);

        //     color: $primary;

        //     &:hover {
        //         color: darken($primary, 10%);
        //     }
        // }
    }
}