main.page-wrapper {
    padding-top: 128px;      
    
    @include media-breakpoint-down(sm) {
        padding-bottom: 56px;
        margin-left: 0;
    }

    @include media-breakpoint-up(md) {
        padding-top: 64px;        
    }

    .sidebar-show & {
        @include media-breakpoint-down(md) {
            margin-left: 260px;        
        }

        @include media-breakpoint-up(md) {
            margin-left: 60px;        
        }
    }

    min-height: 100%;
    // z-index: 800;
    transition: transform .3s ease-in-out,margin .3s ease-in-out;
    margin-left: 260px;
    // z-index: 820;
    // padding-top: 64px;
    
    .has-alert & {
        padding-top: 164px;

        @include media-breakpoint-up(md) {
            padding-top: 100px !important;      
        }
    }

    .page-breadcrumb {
        position: fixed;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            width: calc(100% - 260px);
        }
        
        padding: 15px;
        z-index: 10;

        .contained & {
            width: 100%;
        }
    }

    // .page-content {
    //     padding: 15px;
    // }

    .card-columns {
        @include media-breakpoint-only(lg) {
          column-count: 6;
        }
        @include media-breakpoint-only(xl) {
          column-count: 6;
        }
      }

    .sidebarless & {
        margin-left: 0;
        padding-top: 64px;
    }
}

.sidebar-show .page-breadcrumb {
    @include media-breakpoint-down(md) {
        width: calc(100% - 260px) !important;
    }
    
    @include media-breakpoint-up(md) {
        width: calc(100% - 60px) !important;    
    }
}

.page-breadcrumb.section {
    position: relative !important;
    width: 100% !important;
}

.contained {
    background-color: #f5f5f5 !important;
    height: 100%;

    .card {
        .card-img-top {
            transition: margin-top 0.3s ease-in-out;
            margin-top: -24px;
        }

        .card-text {
            color: $body-color;
        }
    }
}

.bg-mapamundi {
    background-image: url(../images/mapamundi-bg.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: auto;
}