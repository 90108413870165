@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700|PT+Sans+Caption|PT+Sans+Narrow|Poppins:300,400,500,600,700');

html,
body {
    width: 100%; 
    height: 100%;
}

body {
    @include font-family();

    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

h1 {
    @include font-family('Poppins', 300);
}

h2 {
    @include font-family('Poppins', 600);
}

h3 {
    @include font-family('Poppins');
}

h4 {
    @include font-family('Poppins', 700);
}

h5 {
    @include font-family('Poppins', 700);
}

h6 {
    @include font-family('Poppins', 500);
}

.subtitle-1 {
    @include font-family('Poppins', 400);

    font-size: 1rem;
    letter-spacing: 0.15px;
}

.subtitle-2 {
    @include font-family('Poppins', 500);
    @include font-size(16);

    letter-spacing: 0.15px;
}

.body-1 {
    @include font-family('PT Sans', 400);

    font-size: 1rem;
    letter-spacing: 0.5px;
}

.body-2 {
    @include font-family('PT Sans', 400);

    font-size: 0.8125rem;
    letter-spacing: 0.25px;
}

.overline {
    @include font-family('PT Sans Narrow', 600);

    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 1.5px;
}

.caption {
    @include font-family('PT Sans Caption', 400);

    font-size: 1.25rem;
    letter-spacing: 0.4px;
}

.caption-2 {
    @include font-family('PT Sans Caption', 400);
    @include font-size(11);
}

.uppercase {
    text-transform: uppercase;
}

.section {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
}

.page-content {
    margin-top: 5rem;
}
.page-breadcrumb.section  + .page-content {
    margin-top: 1rem;
}

#error {
    background-color: $white;
    overflow: hidden;

    .subtitle-1 {
        @include font-size(24);
    }
}

#error .page-content {
    margin-top: 64px;
}

.brand span {
    @include font-family('Poppins', 700);

    font-size: 18px;
}

input.date {
    background-image: url(../images/vector/icon/calendar--dotted.svg);
    background-repeat: no-repeat;
    background-position: 98% center;
    background-size: 18px;

    &.from {
        background-image: url(../images/vector/icon/date_from--dotted.svg);
    }

    &.to {
        background-image: url(../images/vector/icon/date_to--dotted.svg);
    }
}

input.percent {
    background-image: url(../images/vector/icon/percentage--ios.svg);
    background-repeat: no-repeat;
    background-position: 98% center;
    background-size: 16px;
    padding-right: 1.25rem;
}


input.money {
    background-image: url(../images/vector/icon/us_dollar--material.svg);
    background-repeat: no-repeat;
    background-position: 0.25rem center;
    background-size: 12px;
    padding-left: 1.25rem;

    &.pen {
        background-image: url(../images/vector/icon/money--pen--material.svg);
    }
}

.btn:not(.btn-daterange) {
    //@include font-family('PT Sans Narrow', 600);
    @include font-family('Poppins', 700);
    // @include font-size(13);

    transition: background-color 0.3s ease-in, color 0.1s ease-out;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}

.btn.form-control {
    background-color: $input-bg !important;
    border: $input-border-width solid $input-border-color !important;
}

.card-title {
    @extend .subtitle-2;
    @extend .mb-0;
}

.fill-white {
    fill: white;
}

.fill-black {
    fill: black;
}

.navbar-brand {
    span {
        @include font-family('Poppins', 600);
    }
}
//
// Custom
//

//
// Image radio buttons
//
.img-radio {
    input {
        margin:0;padding:0;
        appearance:none;
    
        &:active,
        &:checked {
            &+.img-radio-label {
                filter: none;
            }
        }
    }
    
    .img-radio-label {
        @extend .rounded;
        @extend .text-center;

        display:block;
        cursor:pointer;
        background-repeat:no-repeat;
        background-size: 80% auto;
        width: 100%;
        //height:auto;
        height: 280px;
        border-width: 2px;
        border-color: rgba(0, 0, 0, 0.25);
        border-style: solid;
        background-position: center bottom;
        transition: all 0.3s ease-in;
        padding: 1rem 1rem 10rem;
        line-height: 1;
        filter: brightness(1.25) grayscale(1) opacity(.7);

        h3 {
            @include font-size(16);
            @include font-family('Poppins', 700);

            transition: color 0.3s ease;
            text-transform: uppercase;
        }
    
        &:hover {
            filter: brightness(1.2) grayscale(.5) opacity(.9);
        }
    }
}

.form-check {
    &.form-check-right {
        padding-left: 0;

        .form-check-input {
            position: relative;
            float: right;
        }
    }

    .form-check-label {
        cursor: pointer;
    }
}

.eniex {
    background-image:url(../images/radio__eniex.svg);
}

.ongd {
    background-image:url(../images/radio__ongd.svg);
}

.ipreda {
    background-image:url(../images/radio__ipreda.svg);
}

.wrapper {
    height: auto;
    min-height: 100%;
    position: relative;
    //height: 100%;
    //height: 100vh;
    //min-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    // overflow-y: auto;

    &::after {
        clear: both;
    }

    &::before,
    &::after {
        content: " ";
        display: table;
    }
}

.transparent {
    background-color: transparent;
}

.spinner {
    top: 0;
    bottom: 0;
}

.nav-actions {
    @extend .nav;
    @extend .justify-content-center;

    min-width: 30px;
    max-width: 100%;

    a, 
    button {
        @extend .nav-link;
        @extend .btn-sm;

        padding: 0.15rem 0.5rem;
        
        i {
            font-size: 0.875rem;
        }
    }
}

.childTable {
    background: #dee2e6 url(../images/vector/icon/curved_arrow--windows.svg) no-repeat 2.25% 50%;
    background-size: 16px;
    padding: 16px;
    
    table {
        margin-left: 16px;

        tbody {
            tr {
                td{
                    background-color: #f1f1f1;
                }
            }
        }
    }
}

//
// Forms
//
.form-buttons {
    text-align: left;
    margin-bottom: 0;
}

.form-label {
    font-weight: 600;
}

.form-label-lg {
    @extend .col-form-label-lg;

    padding: 0;
}

.form-label-sm {
    @extend .col-form-label-sm;

    padding: 0;
    margin-bottom: $label-margin-bottom - 0.15;
}

.input-group-prepended {
    .input-group-prepend {
        .input-group-text {
            border-right: 1px solid transparent;
        }
    }

    .form-control:not(:focus) {
        border-left: 1px solid transparent;
    }
}

.input-group-appended {
    .input-group-append {
        .input-group-text {
            border-left: 1px solid transparent;
        }
    }

    .form-control:not(:focus) {
        border-right: 1px solid transparent;
    }
}

.fill-25 {
    svg * {
        fill: rgba(0, 0, 0, 0.25);
    }
}

.input-group-text:not(.fill-25) svg * {
    fill: url(#lgrad);
}

.with-spinner {
    overflow-x: hidden;
    overflow-y: hidden;
}

.truncate {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//
// Table
//
.table {
    border-left: $table-border-width solid $table-border-color;
    border-right: $table-border-width solid $table-border-color;
    border-bottom: $table-border-width solid $table-border-color;

    tr {
        transition: background-color 0.3s ease-in, color 0.3s ease-in;
    }
    
    th {
        @include font-family('Poppins', 500);
        @include font-size(12);

        line-height: 1.25;
        vertical-align: middle !important;

        small {
            display: block;
        }
    }
    
    td {
        @include font-size(12);
        
        line-height: 1.5;
    }

    td.details-control {
        background: url('../images/vector/icon/double_down--dotted.svg') no-repeat center center;
        background-size: 16px;
        text-align: center;
        cursor: pointer;
        transition: background 0.5 ease-in;
        
        svg {
            transition: all 0.3s ease-in;
        }

        &.details-folder {
            background: url('../images/vector/icon/folder--color.svg') no-repeat center center;
            background-size: 16px;
        }
    }

    tfoot th {
        vertical-align: top;
        border-top: (2 * $table-border-width) solid $table-border-color;
    }

    tr.shown {
        td.details-control {
            background: url('../images/vector/icon/double_up--dotted.svg') no-repeat center center;
            background-size: 16px;

            &.details-folder {
                background: url('../images/vector/icon/opened_folder--color.svg') no-repeat center center;
                background-size: 16px;
            }
        }

        + tr {
            > td {
                padding: 0;

                table {
                    // border-left: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    margin-bottom: 0;
                    border-right: 1px solid transparent;
                    width: calc(100% - 16px);
                }
            }
        }
    }
}

.print {
    th {
        @include font-size(14);
    }
    
    td {
        @include font-size(14);
    } 
}

.border-dashed {
    border: 1px dashed rgba(0,0,0,0.25);
}

//
// Cards
//
.card-header {
    //@extend .subtitle-2; 
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .border-bottom-0;
}

// .card-footer {
//     border-top: $card-border-width solid transparent; 
// }

//
// Tabs
//
.nav-tabs {
    //border-bottom: 3px solid #dee2e6;

    .nav-item {
        // @extend .subtitle-1;

        //background-color: transparent !important;
        //border: 0  !important;
        //border-bottom: 3px solid transparent !important;
        //margin-bottom: -3px;
        @include font-family('Poppins', 700);
        @include font-size(12);

        color: rgba(0, 0, 0, 0.5);
        transition: color 0.3s ease-in-out;
        padding: 0.5rem 0.75rem; 

        svg {
            width: 32px;
            height: 32px;
        }

        &.active,
        &:hover {
            //background-color: transparent;
            color: rgba(0, 0, 0, 0.75);
        }
    }

    margin-bottom: 1.5rem;
}

//
// Modals
//
.modal-footer {
    background-color: rgba(0, 0, 0, 0.085);
}

// label:not(.custom-control-label):not(.custom-file-label) {
//     @extend .overline;
// }

.custom-control {
    &.custom-radio,
    &.custom-checkbox {
        margin-bottom: 0.5rem;

        .custom-control-label {
            cursor: pointer;
            line-height: 1.5;

            span {
                font-weight: 400; 
            }
        }
    }
}

.m-0 {
    margin: 0;
}

.p-0 {
    padding: 0;
}

//
// Header
//
.dropdown-grid {
    width: 320px;

    .dropdown-item {
        padding: 0;
        text-align: center;
    }

    .nav-text {
        @extend .overline;

        display: block;
    }
}


.select2-container .select2-selection--single {
    display: block;
    width: 100%;
    height: $input-height !important;
    padding: $input-padding-y $input-padding-x;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
}

.select2-selection__rendered {
    padding-left: 0 !important;
    padding-right: 0 !important;
    line-height: inherit !important;
}

.select2-selection__arrow {
    height: $input-height !important;
}

.spinner-backdrop {
    @extend .modal-backdrop;

    z-index: 1050 !important;
}

.spinner {
    z-index: 1050 !important;
}

.nav > .nav-item.disabled {
    color: rgba(0, 0, 0, 0.35);
    background-color: transparent;
    border-color: transparent;
    pointer-events: none;
    cursor: default;
}

//
// Showing
//
.nav-tabs .nav-item svg {
    height: inherit;
    transition: height 0.3s ease-in-out;
}

.showing .nav-tabs .nav-item svg {
    height: 0;
}

.tabbed {
    h6 {
        @include font-size(14);

        font-weight: 600;
    }
}

//
// Animated Icons 
//
.icon {
    float: left;
    width: 1em;

    svg {
        display: inline-block;
        width: 1em;
        height: 1em;
        font-size: inherit;
        overflow: visible;
        vertical-align: -0.125em;
    }
}


.print {
    font-family: Arial, Helvetica, sans-serif;
    background-color: white !important;

    h2 {
        text-align: center;
        font-size: 1.125rem;
    }
}

@media print {
    @page {
        margin-top: 2cm;
        margin-bottom: 2cm;
        margin-left: 2cm;
        margin-right: 2cm;
    }
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
  
    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  #mreLogo {
    position: absolute;
    left: 15px;
    top: 15px;
  }

  .input-group {
    .input-group-append,
    .input-group-preppend {
        .btn {
            padding: 0 $btn-padding-x;
        }

        .btn:not(.btn-action):not(.btn-primary):not(.btn-secondary):not(.btn-success):not(.btn-danger):not(.btn-warning):not(.btn-info):not(.btn-light):not(.btn-dark):not(.btn-outline-action):not(.btn-outline-primary):not(.btn-outline-secondary):not(.btn-outline-success):not(.btn-outline-danger):not(.btn-outline-warning):not(.btn-outline-info):not(.btn-outline-light):not(.btn-outline-dark) {
            background-color: $input-bg;
            border: $input-border-width solid $input-border-color;
            color: color-yiq($input-bg);
        }
    }
  }